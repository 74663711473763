/*jslint white: true, nomen: true */
/*global angular, $, communityId */
(function () {
  'use strict';

  angular.module('app.microsite')
    .controller('MicrositeMoveInReadyCtrl', ['$scope',
      function ($scope) {

        const chunk = (input, size) => {
          return input.reduce((arr, item, idx) => {
            return idx % size === 0
              ? [...arr, [item]]
              : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
          }, []);
        };

        $scope.communityPromise
          .then(function () {
            $scope.moveInReady = $scope.community.homes;
            $scope.moveInReadyGroup = chunk($scope.moveInReady, 3);
          });

      }
    ])
    ;
}());
